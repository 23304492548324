.plans {
	display: grid;
	grid-template-columns: 1fr;
	gap:30px;
	margin-top: 60px;
}

.plans .radioboxes {
	display: grid;
	gap: 43px;
	grid-template-columns: 1fr 1fr;
}

.planItem {
	padding: 30px;
	border-radius: 15px;
	display: grid;
	grid-template-columns: 1fr;
	gap: 8px;
	transition: all 0.5s ease;
	position: relative;
	align-items: start;
	justify-items: start;
	background-color: var(--modal-gray);
	z-index: 0;
}

.planItem:hover {
	cursor: pointer;

}

.active {
	background: var(--green-500);
	box-shadow: 0 3px 5px 1px #A6A5A5;
}

.active input[type="radio"] + span::before {
	width: 8px;
	height: 8px;
	content: '';
	position: absolute;
	top: 3px;
	left: 3px;
	background-color:  var(--main-dark);
	border-radius: 5px;
}

.halfYearPlan::before {
	content: 'Most popular!';
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	position: absolute;
	top: -23px;
	text-align: center;
	left: 50%;
	border-radius: 50px;
	background-color: var(--blue-bg);
	padding: 10px 28px;
	width: 200px;
	transform: translate(-50%, 0);
}

.priceTitle {
	text-align: left;
}

.priceTitle h3{
	font-size: 42px;
	font-style: normal;
	line-height: 48px;
	font-weight: 400;
	margin-bottom: 15px;
}

.priceValue {
	font-size: 42px;
	font-style: normal;
	font-weight: 500;
	line-height: 48px;
}



.buyPlan {
	padding: 8px 0;
	cursor: pointer;
	border: 2px solid var(--main-dark);
	font-family: var(--primary-font);
	font-size: 22px;
	font-weight: 400;
	font-style: normal;
	line-height: 40px;
	border-radius: 10px;
}

.backGuaranty {
	margin: 0 auto;
	max-width: calc( var(--max-container-width) - 12% - 30px);
	display: grid;
	grid-template-columns: 127px 1fr;
	gap: 20px;
	margin-top: 60px;
	margin-bottom: 20px;
	align-items: start;
}

.backGuaranty img {
	width: 127px;
}

.guaranty_content {
	text-align: left;
}

.backGuaranty h3 {
	margin-bottom: 10px;
	font-size: 42px;
	font-weight: 400;
}

.backGuaranty p {
	font-size: 19px;
	font-style: normal;
}

.includesList {
	text-align: left;
	max-width: calc( var(--max-container-width) - 12% - 30px);
	padding-top: 30px;
}

.includesList h3 {
	font-size: 42px;
	font-weight: 400;
}

.includesList li {
	font-size: 22px;
	font-style: normal;
	font-weight: 400;
	display: flex;
	gap: 10px;
	align-items: center;
	margin-bottom: 15px;
}

.includesList li svg {
	min-width: 25px
}


@media screen and (max-width: 768px) {
	.plans {
		margin-bottom: 0;
		margin-top: 45px;
	}

	.planItem {
		grid-template-columns: auto 1fr;
		gap: 10px;
	}

	.priceTitle h3{
		font-size: 25px;
		line-height: 30px;
	}

	.priceValue {
		grid-column: -1/1;
		text-align: center;
	}

	.plans .radioboxes {
		grid-template-columns:  1fr;
	}

	.backGuaranty {
		margin-top: 30px;
		grid-template-columns: 1fr;
	}

	.backGuaranty h3 {
		font-size: 25px;
	}

	.guaranty_content {
		padding-top: 0;
	}

	.backGuaranty p {
		font-size: 22px;
	}

	.backGuaranty img {
		width: 60px;
		height: 60px;
		display: block;
		margin: 0 auto;
	}

	.includesList h3 {
		font-weight: 500;
	}

	.guaranty_content h3 {
		font-weight: 500;
	
	}

	.includesList h3 {
		margin-bottom: 20px;
	}

}
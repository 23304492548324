
.nav {
	display: grid;
	align-items: center;
	justify-content: end;
	grid-template-columns: 1fr auto auto;
	gap: 16px;
}

.menu {
	display: flex;
	justify-content: flex-end;
	margin-right:20px;
	gap: 15px;
}

.menuItem {
	position: relative;
	top: 0;
	display: inline-block;
	padding: 3px;
	transition: all 0.3s ease;
}

.menuItem a {
	transition: all 0.3s ease;
	text-decoration: none;
	color: var(--main-dark);
	font-family: var(--primary-font);
	font-size: 18px;
	font-weight: 500;
	font-style: normal;
	line-height: 21px;
}



.menuItem:hover {
	cursor: pointer;
}



.checkbox,
.hamburger_lines {
	display: none;
}

.entrances {
	display: flex;
	gap: 15px;
	align-items: center;
	transition: all 0.5s ease-in;
	height: auto;
}

.nav_logout_icons {
	display: flex;
	gap: 20px;
	align-items: center;
}

.mobile_entrance{
	display: none;
	align-items: center;
}

.mobile_entrance a {
	margin-top: 10px;
}

.mobile_entrance:hover {
	cursor: pointer;
}

@media screen and (max-width: 1100px) {
	.menu {
		margin-right: 10px;
		gap: 9px;
	}

	.menu a {
		font-size: 16px;
	}
}

@media (max-width: 1024px)  {
	.nav {
		justify-items: end;
		grid-template-columns: 1fr auto;
		position: sticky;
		top: 0;
	}

	.checkbox {
		position: absolute;
		z-index: 5;
		top: 0;
		right: 0;
		display: block;
		width: 23px;
		height: 18px;
		cursor: pointer;
		opacity: 0;
	}

	.hamburger_lines {
		position: relative;
		z-index: 2;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		width: 24px;
		height: 18px;
	}
	
	.menu {
		position: fixed;
		top:0;
		left: 0;
		flex-direction: column;
		justify-content: start;
		width: 100%;
		height: 100svh;
		padding: 13px ;
		padding-top: 130px;
		transition: transform 0.5s ease-in-out;
		transform: translateX(150%);
		background-color: var(--second-logo-color);
		will-change: transform;
	}

	.menuItem a {
		font-size: 24px;
		font-weight: 500;
		font-style: normal;
		line-height: 27px;
	}

	.entrances {
		position: fixed;
		top: 0;
		left: 0;
		gap: 20px;
		background-color: var(--second-logo-color);
		width: 100%;
		height: 100svh;
		padding-top: 100px;
		align-items: flex-start;
		justify-content: center;
		transform: translateX(150%);
	}

	.line {
		display: block;
		width: 100%;
		height: 2px;
		border-radius: 3px;
		background: #0E2431;
	}

	.line1 {
		transition: transform 0.4s ease-in-out;
		transform-origin: 0% 0%;
	}

	.line2 {
		transition: transform 0.2s ease-in-out;
	}

	.line3 {
		transition: transform 0.4s ease-in-out;
		transform-origin: 0% 100%;
	}

	input[type="checkbox"]:checked ~ .menu {
		transform: translateX(0);
	}

	input[type="checkbox"]:checked ~ .hamburger_lines .line1 {
		transform: rotate(45deg);
	}

	input[type="checkbox"]:checked ~ .hamburger_lines .line2 {
		transform: scaleY(0);
	}

	input[type="checkbox"]:checked ~ .hamburger_lines .line3 {
		transform: rotate(-45deg);
	}

	.mobile_show {
		transform: translateX(0);
	}

	.mobile_entrance {
		position: relative;
		z-index: 999;
		display: block;
	}
}

@media screen and (max-width: 768px) {
	.menuItem a:after {
		display: none;
	}
}

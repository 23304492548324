.header {
	height: 100svh;	
	padding-top: 100px;
	background: url(./header_bg.png) no-repeat 100% 80px;
	background-size: cover;
}

.smartBanner {
	text-align: center;
	padding: 30px 50px;
	background-color: var(--main-dark);
	color: var(--white);
	font-size: 22px;
	display: flex;
	z-index: 1;
	position: relative;
	justify-content: center;
}

.smartBanner span {
	position: relative;
	display: inline;
}

.smartBanner span::after {
	content: '';
	position: absolute;
	width: 100%;
	height: 1px;
	bottom: -3px;
	left: 0;
	background-color: #fff;
	transition: transform 0.25s ease-out;
	transform: scaleX(1);
	transform-origin: bottom left;
}

.smartBanner span:hover::after {
	transform: scaleX(0);
	transform-origin: bottom right;
}

.frontOffice {
	background: var(--second-logo-color);
}

.middleOffice {
	background: var(--third-logo-color);
	max-height: 519px;
}

.header .wrapper {
	display: grid;
	align-items: center;
	max-width: var(--max-container-width);
	height: 100svh;
	margin: 0 auto;
	grid-template-columns:  minmax(20%, 30%) 1fr;
	grid-template-rows: 1fr;
	gap: 0 10px;
	padding: 100px 0;
}

.middleOffice .wrapper {
	max-height: 519px;
}

.blogLayout, 
.blogLayout .wrapper  {
	grid-template-columns: 1fr;
	height: auto;
}

.blogLayout .headling,
.blogLayout .heroImg{
	display: none;
}

h1.heroTitle {
	font-weight: 500;
	font-size: 50px;
}

.topHeader {
	transition: all 0.5s ease;
	transform: translateZ(0);
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 7;
}

.topHeaderNav {
	grid-column: 1 / -1; 
	grid-template-columns: auto 1fr;
	padding-right: 15px;
	padding-left: 15px;
	display: grid;
	align-items: center;
	justify-content: space-between;
	padding-top: 10px;
}

.frontOffice .topHeaderNav  {
	background-color:  var(--second-logo-color); 
}

.middleOffice
.topHeaderNav {background-color:  var(--third-logo-color); }

/* .topHeaderNav  a:hover, */
/* .topHeaderNav  a.active  { */
/* color: var(--middle-blue)!important; */
/* } */

.frontOffice  .topHeaderNav  a:hover {
	color: var(--main-dark)!important;
}

.middleOffice .desktopLogo span,
.frontOffice .desktopLogo span {
	color: var(--white)
}

/* .middleOffice .topHeaderNav  a:hover, */
/* .frontOffice .topHeaderNav  a.active  { */
/* color: var(--white)!important; */
/* } */

.sticky .topHeader {
	position: fixed;
	z-index: 999;
	top: 0;
	left: 0;
	width: 100%;
	transform: rotate(360deg);
	animation:slide-down 0.7s;
}

.sticky .smartBanner {
	display: none;
}

.sticky .topHeaderNav {
	background: var(--second-logo-color); padding-bottom: 10px;
}

.middleOffice.sticky .topHeaderNav {
	background: var(--third-logo-color);
}

.pricing_header,
.pricing_header .topHeaderNav {
	height: auto;
	background: var( --dashboard-bg);
}

.pricing_header {
	margin-bottom: 50px;
}

.pricing_header.header .wrapper {
	height: auto; 
	padding: 0;
}

.pricing_header .headling,
.pricing_header .heroImg {
	display: none;
}

.pricing_header .desktopLogo {
	color: var(--third-logo-color)
}

.pricing_header .desktopLogo span {
	color: var(--main-dark);
}


@keyframes slide-down {
	0% {
		transform: translateY(-100%);
		opacity: 0;
	} 

	100% {
		transform: translateY(0);
		opacity: 0.9;
	} 
}

.headling {
	display: grid;
	align-items: center;
	gap: 30px;
	padding-right: 15px;
	padding-left: 15px; 
}

.heroImg {
	text-align: center;
}

.heroImg img {
	max-width: 100%;
}

.logo img {
	max-width: 207px;
}

.mobileLogo {
	display: none;
}

.headling p {
	letter-spacing: -0.01em;
	font-family: var(--primary-font);
	font-size: 24px;
	font-weight: 500;
	font-style: normal;
	line-height: 25px;
}


.headling .heroDesc {
	font-size: 23px;
	line-height: 25px;
}

.groupButton {
	display: flex;
	gap: 20px;
	margin-top: 20px;
} 

.groupButton button {
	width: 100%;
}

.submit {
	margin-top: 20px;
}

.askForSupport {
	font-size: 24px;
}

.askForSupport p {
	font-size: 24px;
	margin-bottom: 20px;
}

.m_header_img_container {
	position: relative;
	height: 219px;
	overflow: hidden;
	width: calc(100% + 44px);
	margin-bottom: -20px;
	margin-left: -22px;
	margin-right: -22px;
}

.m_header_img {
	max-width: 140%;
	position: absolute;
	right: 0;
	top: -10px;
	
}

@media screen and (max-height: 668px) {
	.header{
		height: auto;
	}

	
}

@media screen and (max-width:1024px) {
	.header {
		height: auto;
		padding-bottom: 0;
		z-index: 2;
	
	}

	.pricing_header {
		padding-bottom: 0;
		margin-bottom: 0;
		padding-top: 0;
	}

	.topHeaderNav {
		padding: 28px 20px;
	}

	.header .wrapper {
		
		grid-template-columns: 1fr;
		grid-template-areas: 
			
			"heroImg"
			"heroTitle";
		height: auto;
		padding-top: 40px;
		padding-left: 20px;
		padding-right: 20px;
		padding-bottom: 0;
	}

	.header .mobile_heading {
		grid-template-columns: 1fr;
		grid-template-areas:
			"topHeader"
			
			"heading"
			"heroImg";
		height: auto;
		
	}

	.headling {
		padding-top: 30px;
		padding-bottom: 30px;
	}



	h1.heroTitle {
		font-size: 42px;
	}

	.heroDesc {
		font-size: 22px;
	}
	

	.sticky .wrapper {
		grid-template-areas: 
			"heroImg"
			"heroTitle";
	}	

	.sticky  .mobile_heading {
		grid-template-columns: 1fr;
		grid-template-areas:
			"heading"
			"heroImg";
		height: auto;
	}

	.topHeader {
		grid-area: topHeader;
	}

	.heroImg {
		grid-area: heroImg;
		width: 50%;
		margin: 30px auto;
	}

	.mobile_heading .heroImg {
		max-width: 255px;
		padding-left: 20px;
	}

	.mobileLogo {
		position: relative;
		z-index: 999;
		display: block;
	}

	.mobileLogo img {
		max-width: 50px;
	}

	.groupButton {
		align-items: flex-start;
		flex-direction: column;
	}

	.mobile_heading .headling {
		padding-top: 80px;
		grid-area: heading;
	}

	.mobile_heading .headling p {
		font-size: 36px;
		line-height: 39px;
	}


}


@media screen and (max-width: 768px) {
	.header {
		background: none;
	}

	.headling {
		padding-left: 0;
		padding-right: 0;
	}

	.m_header_img_container {
		margin-left: -40px;
		width: calc(100% + 80px);
	}

	.m_header_img {
		/* max-width: 100%; */
	}

}
@media screen and (max-width: 660px) {
	.mobile_heading .headling {
		margin-top: 0;
		padding-top: 20px;
	}

	.smartBanner {
		padding: 25px;
	}
}